import { WeatherForecastModel } from "./WeatherForecast.model";
import {
  deleteHookFactory,
  fetchServiceHookFactory,
  saveHookFactory,
} from "../../hooks/hook-factory";
import { BaseHttpService } from "../../services/http.service";
import { orderBy } from "lodash";

class WeatherForecastService extends BaseHttpService {
  public listPublic() {
    return this.get<WeatherForecastModel[]>("");
  }
  public list() {
    return this.get<WeatherForecastModel[]>("private");
  }

  public create(forecast: WeatherForecastModel): Promise<void> {
    return this.post("create", forecast);
  }

  public update(forecast: WeatherForecastModel): Promise<void> {
    const id = 4;
    return this.put(`update/${id}`, forecast);
  }

  public remove(id: number): Promise<void> {
    return this.delete(`delete/${id}`);
  }
}

const service = new WeatherForecastService(
  "https://localhost:7176/api/WeatherForecast/"
);
export const useListPublicWeather = fetchServiceHookFactory(
  service,
  service.listPublic
);
export const useListPrivateWeather = fetchServiceHookFactory(service, service.list, {
  interceptor: (response): WeatherForecastModel[] =>
    orderBy(response, (x) => x.date),
});
export const useSaveWeather = saveHookFactory(
  service,
  service.create,
  service.update
);
export const useRemoveWeather = deleteHookFactory(service, service.remove);