import React from "react";
import { getErrorMessage } from "../../utils/error-handler";
import { useListPrivateWeather, useRemoveWeather, useSaveWeather } from "./weather.service";
import { WeatherForecast } from "./WeatherForecast";
import { WeatherForecastModel } from "./WeatherForecast.model";

export const WeatherForecastWebApi: React.FC = () => {
  const [data, asyncState, refresh] = useListPrivateWeather();
  const { loading, error } = asyncState;

  const onSuccess = React.useCallback(() => {
    refresh();
  }, [refresh]);
  const [save, saveAsyncState] = useSaveWeather(onSuccess); // eslint-disable-line @typescript-eslint/no-unused-vars

  const newWeather = React.useMemo<WeatherForecastModel>(
    () => ({
      date: new Date(),
      temperatureC: 12,
      temperatureF: 25,
      summary: "new weather is awesome!",
    }),
    []
  );
  const onSave = React.useCallback<VoidFunction>(() => save(newWeather), [newWeather, save]);

  const [remove, removeAsyncState] = useRemoveWeather(onSuccess); // eslint-disable-line @typescript-eslint/no-unused-vars
  const onRemove = React.useCallback<VoidFunction>(() => remove(31), [remove]);

  return loading ? (
    <div>Loading...</div>
  ) : error ? (
    <div>{getErrorMessage(error)}</div>
  ) : (
    <div>
      <h1>Weather from private WebAPI</h1>
      <div>
        <button onClick={refresh}>Reload weather</button>
        <button onClick={onSave}>Save</button>
        <button onClick={onRemove}>Delete</button>
      </div>
      <WeatherForecast forecasts={data}></WeatherForecast>
    </div>
  );
};
