import { AxiosRequestConfig } from 'axios';
import merge from 'lodash/merge';
import { HttpClient } from './http-client';

export abstract class BaseHttpService {
    private readonly apiUrl: string;

    constructor(apiUrl: string) {
        if (apiUrl === undefined) {
            throw new Error('Missing URL.');
        }
        this.apiUrl = apiUrl;
    }

    protected get<T>(url: string, config?: RequestConfig): Promise<T> {
        return this.request<unknown, T>('GET', url, undefined, config);
    }

    protected post<TIn, TOut>(url: string, data?: TIn, config?: RequestConfig): Promise<TOut> {
        return this.request<TIn, TOut>('POST', url, data, config);
    }

    protected put<TIn, TOut>(url: string, data?: TIn, config?: RequestConfig): Promise<TOut> {
        return this.request<TIn, TOut>('PUT', url, data, config);
    }

    protected delete<TIn, TOut>(url: string, data?: TIn, config?: RequestConfig): Promise<TOut> {
        return this.request<TIn, TOut>('DELETE', url, data, config);
    }

    private request<TIn, TOut>(method: HttpRequestType, url: string, data?: TIn, config?: RequestConfig): Promise<TOut> {
        const requestConfig: AxiosRequestConfig = this.prepareRequestConfiguration(method, url, data, config);
        return HttpClient.instance.request<TOut>(requestConfig).then(response => response.data);
        // return Axios.request<TOut>(requestConfig); - if this would need to be used, default axios interceptor should be set by setDefaultAxiosTokenInterceptor
    }

    private prepareRequestConfiguration<T = unknown>(
        method: HttpRequestType,
        url: string,
        data?: T,
        configurationOverride?: RequestConfig): AxiosRequestConfig {
        return merge(
            {
                method,
                url,
                baseURL: this.apiUrl,
                data,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                }
            },
            configurationOverride
        );
    }
}

type HttpRequestType = 'GET' | 'POST' | 'PUT' | 'DELETE';
type RequestConfig = Pick<AxiosRequestConfig, 'onUploadProgress' | 'headers' | 'responseType' | 'params'>;
