import Axios, { AxiosRequestConfig } from "axios";

const authAxiosWithTokenInterceptor = (token: string) => {
  // axios instance for making requests
  const axiosInstance = Axios.create();

  // request interceptor for adding token
  axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
    // add token to request headers
    if (!token) {
      return config;
    }
    return {
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${token}` },
    };
  });

  return axiosInstance;
};
export default authAxiosWithTokenInterceptor;
//usage
//import authAxios from "../services/custom-axios";
// const res = await authAxios(token).request({ ...fetchOptions });
