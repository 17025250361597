import React from "react";
import { WeatherForecastWebApiHookFactory } from "./WeatherForecastHookApi";
import { WeatherForecastWebApi } from "./WeatherForecastWebApi";

export const WeatherForecastPage: React.FC = () => (
  <div>
    <WeatherForecastWebApi />
    <hr/>
    <WeatherForecastWebApiHookFactory />
  </div>
);
