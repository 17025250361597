import axios, { Axios } from "axios";

//https://stackoverflow.com/a/68407340/3096092
export class HttpClient {
  static instance: Axios;
  constructor() {
    HttpClient.instance = axios.create(); //({ baseURL: process.env.API_BASE_URL });

    HttpClient.instance.interceptors.request.use(
      async (config) => {
        const token = await this.getToken();
        if (!token) {
            return config;
        }
        return {
          ...config,
          headers: { ...config.headers, Authorization: `Bearer ${token}` },
        };
      },
      (error) => {
        Promise.reject(error);
      }
    );

    return this;
  }

  public setTokenGenerator(tokenGenerator: TTokenGeneratorFunc) {
    this.tokenGenerator = tokenGenerator;
    return this;
  }

  public getToken() {
    return this.tokenGenerator();
  }

  private tokenGenerator: TTokenGeneratorFunc;
}
type TTokenGeneratorFunc = () => Promise<string>;

export const httpClientInstance = new HttpClient();

//USAGE:
//On my App root,
//import { httpClientInstance } from "./services/http-client";
//   useEffect(() => {
//     httpClientInstance.setTokenGenerator(getAccessTokenSilently);
//   }, [getAccessTokenSilently]);
