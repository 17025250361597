import { useAuth0 } from '@auth0/auth0-react';
import { AxiosRequestConfig, Method } from 'axios';
import React from 'react';
import authAxios from '../services/auth-axios';
import { BaseHttpService } from '../services/http.service';

export function isIdZero(id: number | string): boolean {
    return +id === 0;
}

export function fetchServiceHookFactory<TService extends BaseHttpService, TArgs extends unknown[], TDto, TReturn = TDto>(
    service: TService,
    action: FetchingAction<TArgs, TDto>,
    options?: FetchHookFactoryOptions<TArgs, TDto, TReturn>
) {
    return (...params: TArgs): [TReturn, AsyncState, TypedFunction<Promise<void>>] => {
        const [item, setItem] = React.useState<TReturn>();
        const [asyncState, setAsyncState] = React.useState<AsyncState>({ loading: false, status: "idle" });
        const get = React.useCallback<TypedFunction<Promise<void>>>(
            async () => {
                try {
                    if (!options?.executionCondition || options.executionCondition(...params)){
                        setAsyncState({ loading: true, status: 'pending' });
                        let response: TDto;
                        if (options?.defaultIf && options?.defaultIf(...params) === true) {
                            response = options.defaultValue || ({} as TDto);
                        } else {
                            response = await action.call(service, ...params);
                        }
                        const value = options?.interceptor?.(response) ?? response;
                        setItem(value as TReturn);
                        setAsyncState({ loading: false, status: 'success' });
                    }
                } catch (error) {
                    setItem(undefined);
                    setAsyncState({ loading: false, status: 'error', error });
                }
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [...params]
        );
        React.useEffect(() => { get(); }, [get]);
            
        return [item!, asyncState, get]; // TODO: remove the ! - correct the typing
    };
}

export function fetchHttpHookFactory<TDto, TReturn = TDto, TArgs extends unknown[] = []>(
    url: string,
    method: Method,
    data?: object,
    config: AxiosRequestConfig = {},
    options?: FetchHookFactoryOptions<TArgs, TDto, TReturn>,
    immediate = true
) {
    return (...params: TArgs): [TReturn, AsyncState, TypedFunction<Promise<void>>] => {
        const { getAccessTokenSilently } = useAuth0();
        const [item, setItem] = React.useState<TReturn>();
        const [asyncState, setAsyncState] = React.useState<AsyncState>({ loading: false, status: "idle" });
        const get = React.useCallback<TypedFunction<Promise<void>>>(
            async () => {
                try {
                    if (!options?.executionCondition || options.executionCondition(...params)){
                        setAsyncState({ loading: true, status: 'pending' });
                        let response: TDto;
                        if (options?.defaultIf && options?.defaultIf(...params) === true) {
                            response = options.defaultValue || ({} as TDto);
                        } else {
                            const token = await getAccessTokenSilently();
                            const requestData = { ...data, ...params };
                            const requestConfig = { ...config, url: url, method: method, data: requestData };
                            const axiosResponse = await authAxios(token).request({ ...requestConfig });
                            response = axiosResponse?.data;
                        }
                        const value = options?.interceptor?.(response) ?? response;
                        setItem(value as TReturn);
                        setAsyncState({ loading: false, status: 'success' });
                    }
                } catch (error) {
                    setItem(undefined);
                    setAsyncState({ loading: false, status: 'error', error });
                }
            },
            [...params] // eslint-disable-line react-hooks/exhaustive-deps
        );
        React.useEffect(() => { 
            if (immediate) {
                get(); 
            }
        }, [get]);
            
        return [item!, asyncState, get]; // TODO: remove the ! - correct the typing
    };
}

type FetchHookFactoryOptions<TArgs extends unknown[], TDto, TReturn = TDto> = {
    interceptor?: Interceptor<TDto, TReturn>,
    defaultIf?: Condition<TArgs>,
    defaultValue?: TDto,
    executionCondition?: Condition<TArgs>
};
type FetchingAction<TArgs extends unknown[], TReturn> = (...params: TArgs) => Promise<TReturn>;
type Interceptor<TArg, TReturn> = (response: TArg) => TReturn;
type Condition<TArgs extends unknown[]> = (...params: TArgs) => boolean;

export function deleteHookFactory<TService extends BaseHttpService>(service: TService, action: DeleteAction) {
    return (onSuccess: VoidFunction): [ArgFunction<any>, AsyncState] => {
        const [asyncState, setAsyncState] = React.useState<AsyncState>({ loading: false, status: "idle" });
        
        const remove = React.useCallback<ArgFunction<any>>(
            async (itemOrId: any) => {
                setAsyncState({ loading: true, status: 'pending' });
                try {
                    await action.call(service, itemOrId);
                    setAsyncState({ loading: false, status: 'success' });
                    onSuccess();
                } catch (error) {
                    setAsyncState({ loading: false, status: 'error', error });
                }
            },
            [onSuccess]
        );

        return [remove, asyncState];
    };
}
type DeleteAction = (itemOrId: any) => Promise<void>;

export function saveHookFactory<TService extends BaseHttpService>(service: TService, createAction: SaveAction, updateAction: SaveAction) {
    return (onSuccess: VoidFunction): [ArgFunction<any>, AsyncState] => {
        const [asyncState, setAsyncState] = React.useState<AsyncState>({ loading: false, status: "idle" });
        
        const save = React.useCallback<ArgFunction<any>>(
            async (item: any) => {
                setAsyncState({ loading: true, status: 'pending' });
                try {
                    if (item.id) {
                        await updateAction.call(service, item);
                    } else {
                        item.id = await createAction.call(service, item);
                    }
                    setAsyncState({ loading: false, status: 'success' });
                    onSuccess();
                } catch (error) {
                    setAsyncState({ loading: false, status: 'error', error });
                }
            },
            [onSuccess]
        );

        return [save, asyncState];
    };
}
type SaveAction = (item: any) => Promise<void | number>;
