import logo from "./logo.svg";
import "./App.scss";
import { Routes, Route, Link } from "react-router-dom";
// import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { About, Home } from "./Pages";
import { Invoices, IndividualInvoice, SentInvoices } from "./NestedRoutes";
import { useAuth0 } from "@auth0/auth0-react";
import { RedirectSpinner } from "./Spinners";
import { useEffect } from "react";
import { httpClientInstance } from "./services/http-client";
import { getErrorError } from "./utils/error-handler";
import { WeatherForecastPage } from "./components/weather/WeatherForecastPage";

function App() {
  const {
    isLoading,
    isAuthenticated,
    error: auth0Error,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0<{ name: string }>();

  useEffect(() => {
    async function getToken() {
      try {
        if (isAuthenticated) {
          const token = await getAccessTokenSilently();

          console.log("token", token);
        }
        httpClientInstance.setTokenGenerator(getAccessTokenSilently);
      } catch (e) {
        if (getErrorError(e) === "login_required") {
          loginWithRedirect();
        }
        if (getErrorError(e) === "consent_required") {
          loginWithRedirect();
        }
        console.error(e);
      }
    }
    getToken();
  }, [isAuthenticated, getAccessTokenSilently, loginWithRedirect]); // Or [] if effect doesn't need props or state

  if (isLoading) {
    return <div>Auth0 Loading...</div>;
  }
  if (auth0Error) {
    return <div>Oops... Auth0 error: {auth0Error.message}</div>;
  }

  let authElem = <button onClick={loginWithRedirect}>Log in</button>;
  if (isAuthenticated) {
    authElem = (
      <div>
        Hello {user?.name}{" "}
        <button onClick={() => logout({ returnTo: window.location.origin })}>
          Log out
        </button>
      </div>
    );
  }

  return (
    <div className="App App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <h1>Welcome to React Router!</h1>
      {authElem}
      <nav>
        <Link to="/">Home</Link> | <Link to="about">About</Link> |{" "}
        <Link to="invoices">Invoices</Link> | <Link to="weather">Weather</Link>
      </nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route
          path="weather"
          element={
            <RequireAuth>
              <WeatherForecastPage />
            </RequireAuth>
          }
        />
        <Route
          path="invoices"
          element={
            <RequireAuth>
              <Invoices />
            </RequireAuth>
          }
        >
          <Route path=":invoiceId" element={<IndividualInvoice />} />
          <Route path="sent" element={<SentInvoices />} />
        </Route>
      </Routes>
    </div>
  );
}

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0<{ name: string }>();
  // const location = useLocation();
  // const navigate = useNavigate();
  // const from = location.state?.from?.pathname || "/";

  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    loginWithRedirect();
    return <RedirectSpinner />;
    // return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export default App;
