import React from "react";
import { Link, Outlet, useParams } from "react-router-dom";

export const Invoices: React.FC = () => (
  <div>
    <h2>Invoices</h2>
    <nav>
      <Link to="/">Home</Link> | <Link to="323">Individual invoice</Link> |{" "}
      <Link to="sent">Invoices Sent</Link>
    </nav>
    {/*
    This element renders the element for the child route, which in
    this case will be either <SentInvoices> or <IndividualInvoice>
  */}
    <Outlet />
  </div>
);

export const IndividualInvoice = () => {
  let { invoiceId } = useParams();
  return <h3>Invoice {invoiceId}</h3>;
};

export const SentInvoices = () => <h3>Sent Invoices</h3>;
