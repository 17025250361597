type ErrorWithMessage = {
  message: string;
};

type ErrorWithError = {
  error: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown): string {
  return toErrorWithMessage(error).message;
}

function isErrorWithError(error: unknown): error is ErrorWithError {
  return (
    typeof error === "object" &&
    error !== null &&
    "error" in error &&
    typeof (error as Record<string, unknown>).error === "string"
  );
}

function toErrorWithError(maybeError: unknown): ErrorWithError {
  if (isErrorWithError(maybeError)) return maybeError;

  try {
    return { error: JSON.stringify(maybeError) }
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return { error: JSON.stringify(maybeError) }
  }
}

export function getErrorError(error: unknown): string {
  return toErrorWithError(error).error;
}