import React, { useState } from "react";
import { getErrorMessage } from "../../utils/error-handler";
import { useListPrivateWeather, useWeatherApi } from "./useWeather.hook";
import { WeatherForecast } from "./WeatherForecast";
import { WeatherForecastModel } from "./WeatherForecast.model";

export const WeatherForecastWebApiHookFactory: React.FC = () => {
  // const [dataX, asyncStateX, refreshX] = useListPrivateWeather();
  const [data, asyncState, refresh] = useListPrivateWeather();
  const { loading, error } = asyncState;

  // const onSuccess = React.useCallback(() => {
  //   refresh();
  // }, [refresh]);
  // const [save, saveAsyncState] = useSaveWeather(onSuccess); // eslint-disable-line @typescript-eslint/no-unused-vars

  // const newWeather = React.useMemo<WeatherForecastModel>(
  //   () => ({
  //     date: new Date(),
  //     temperatureC: 12,
  //     temperatureF: 25,
  //     summary: "new weather is awesome!",
  //   }),
  //   []
  // );
  // const onSave = React.useCallback<VoidFunction>(() => save(newWeather), [newWeather, save]);

  // const [remove, removeAsyncState] = useRemoveWeather(onSuccess); // eslint-disable-line @typescript-eslint/no-unused-vars
  // const onRemove = React.useCallback<VoidFunction>(() => remove(31), [remove]);

  return loading ? (
    <div>Loading...</div>
  ) : error ? (
    <div>{getErrorMessage(error)}</div>
  ) : (
    <div>
      <h1>Weather from private WebAPI (hook factory)</h1>
      <div>
        <button onClick={refresh}>Reload weather (HA)</button>
        {/* <button onClick={onSave}>Save (HA)</button>
        <button onClick={onRemove}>Delete (HA)</button> */}
      </div>
      <WeatherForecast forecasts={data}></WeatherForecast>
    </div>
  );
};


export const WeatherForecastWebApiAxiosHook: React.FC = () => {
  const [data, setData] = useState<WeatherForecastModel[]>([]);
  const { list, create, remove} = useWeatherApi();
  
  const load = React.useCallback<VoidFunction>(
    async () => {
        const forecasts = await list();
        setData(forecasts);
    },
    [list]
  );

  const newWeather = React.useMemo<WeatherForecastModel>(
    () => ({
      date: new Date(),
      temperatureC: 12,
      temperatureF: 25,
      summary: "new weather is awesome!",
    }),
    []
  );
  const onSave = React.useCallback<VoidFunction>(
    async () => {
          await create(newWeather);
          load();
      },
    [create, newWeather, load]
  );

  const onRemove = React.useCallback<VoidFunction>(async () => {
      await remove(31);
      load();
  }, [load, remove]);

  return (
    <div>
      <h1>Weather from private WebAPI (hook axios)</h1>
      <div>
        <button onClick={load}>Reload weather (HA)</button>
        <button onClick={onSave}>Save (HA)</button>
        <button onClick={onRemove}>Delete (HA)</button>
      </div>
      <WeatherForecast forecasts={data}></WeatherForecast>
    </div>
  );
};
