import React from "react";
import { useLocation } from "react-router-dom";

export interface Props {
  //TODO: add more props
}

export const Home: React.FC<Props> = () => (
  <React.Fragment>
    <main>
      <h2>Welcome to the homepage!</h2>
      <p>You can do this, I believe in you.</p>
    </main>
  </React.Fragment>
);

export const About = () => (
  <React.Fragment>
    <main>
      <h2>Who are we?</h2>
      <p>That feels like an existential question, don't you think?</p>
    </main>
  </React.Fragment>
);

export const Contact = () => <h1>Contact Page</h1>;

export const LoginPage = () => {
  // let navigate = useNavigate();
  let location = useLocation();
  // let auth = useAuth();

  let from = location.state?.from?.pathname || "/";

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    // let formData = new FormData(event.currentTarget);
    // let username = formData.get("username") as string;

    // auth.signin(username, () => {
    //   // Send them back to the page they tried to visit when they were
    //   // redirected to the login page. Use { replace: true } so we don't create
    //   // another entry in the history stack for the login page.  This means that
    //   // when they get to the protected page and click the back button, they
    //   // won't end up back on the login page, which is also really nice for the
    //   // user experience.
    //   navigate(from, { replace: true });
    // });
  }

  return (
    <div>
      <p>You must log in to view the page at {from}</p>

      <form onSubmit={handleSubmit}>
        <label>
          Username: <input name="username" type="text" />
        </label>{" "}
        <button type="submit">Login</button>
      </form>
    </div>
  );
};