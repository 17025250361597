import { WeatherForecastModel } from "./WeatherForecast.model";
import useAxios from "../../hooks/useAxios.hook";
import { fetchHttpHookFactory } from "../../hooks/hook-factory";

export const useWeatherApi = () => {
  const { get, post, put, remove } = useAxios("https://localhost:7176/api/WeatherForecast");
  return {
    listPublic: (): Promise<WeatherForecastModel[]> => get('').then(response => response.data),
    list: (): Promise<WeatherForecastModel[]> => get('private').then(response => response.data),
    create: (forecast: WeatherForecastModel) => post('create', forecast).then(response => response.data),
    update: (forecast: WeatherForecastModel) => put(`update/1234`, forecast).then(response => response.data),
    remove: (id: number): Promise<void> => remove(`delete/${id}`).then(),
  }
};

export const useListPrivateWeather = fetchHttpHookFactory<WeatherForecastModel[]>(
  'https://localhost:7176/api/WeatherForecast/private',
  'GET');