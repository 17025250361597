import React from "react";
import { WeatherForecastModel } from "./WeatherForecast.model";

// WeatherForecastWebApi
// WeatherForecastFuncApi
// WeatherForecastHookApi

export interface Props {
  forecasts?: WeatherForecastModel[];
}

export const WeatherForecast: React.FC<Props> = ({ forecasts }) => (
  <div>
    {!forecasts || forecasts.length === 0 ? (
      <div>No forecasts!</div>
    ) : (
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Temp. (C)</th>
            <th>Temp. (F)</th>
            <th>Summary</th>
          </tr>
        </thead>
        <tbody>
          {forecasts.map((forecast, index) => (
            <tr key={index}>
              <td>
                {new Intl.DateTimeFormat("en-GB", {
                  year: "numeric",
                  month: "numeric",
                  day: "2-digit",
                }).format(new Date(forecast.date))}
              </td>
              <td>{forecast.temperatureC}</td>
              <td>{forecast.temperatureF}</td>
              <td>{forecast.summary}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
);
